<template>
  <!--酒店相册-->
  <div class="ca_out">
    <div class="ca_title_out d_f ali_c">
      <div class="cur_p">
        <!--<img src="./img/order_back.png" alt="" v-if="showPop" class="cur_p close_pop" @click="show = false">-->
        <img src="./img/close_sp.png" alt="" @click="$router.go(-1)">
      </div>
      <div class="ca_title t_a flex_1">酒店图片</div>
    </div>
    <section class="ca_content_out">
      <van-image
        :show-index="false"
        :key="index"
        v-for="(item,index) in imgs"
        @click="seeImg(index)"
        fit="cover"
        :src="item"
      />
    </section>
    <!--<van-image-preview :startPosition="startPosition" v-model="showPop" :images="imgs"></van-image-preview>-->
  </div>
</template>

<script>
  import  { Image, ImagePreview} from 'vant'
  import apis from './utils/apis'
  export default {
    name: "hotelAlbum",
    components:{
      // 'van-image-preview': ImagePreview,
      'van-image': Image,
    },
    data() {
      return {
        imgs:[],
        infoParam:{
          token:this.$global.token,
          sellerId: this.$route.query.id, //商家id
          longitude: null, //经度
          latitude: null, //纬度
        },
        startPosition:0,
        showPop:false,
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      this.getInfo()
    },
    methods: {
      getInfo(){
        this.infoParam.token = this.$global.token;
        this.$get({
          url: apis.getHotelImages,
          params: this.infoParam
        }).then(rsp => {
          this.imgs = [];
          rsp.map((item,index) => {
            this.imgs.push(item.url)
          })
        });
      },
      seeImg(index){
        this.startPosition = index;
        this.showPop = true;
        ImagePreview({
          images: this.imgs,
          startPosition: index,
          closeable: true,
          closeIcon: 'cross',
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .close_pop{
    position: fixed;
    left: 15px;
    top: 15px;
    z-index: 9999;
    width: 15px;
  }
  .ca_out{
    .ca_content_out{
      .van-image{
        margin: 0 13px 13px 0;
        width: calc((100% - 18px) / 2);
        height: 159px;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
      height: calc(100% - 57px);
      box-sizing: border-box;
      padding: 21px;
    }
    height: 100vh;
    background-color: white;
  }
</style>
